export const colors = {
	darkGray: '#101522',
	secondary: '#646464',
	lightGray: '#EAEEF1',
	lightGrayLt: '#F1F4F5',

	blue: '#274ecb',
	darkBlue: '#1033a8',
	white: '#ffffff',
}
